import * as React from "react"
import { useState } from "react"
import Header from "../components/head"
import Footer from "../components/footer"
import { SEO } from "../components/seo"
import Papers from "../components/papers"
import "@fontsource/newsreader"
import "@fontsource/inter/400.css"
import electionVideo from "../../static/election.mp4"
import { StaticImage } from "gatsby-plugin-image"

const Work = () => { 
  return (
    <div className="overarching-div">
          <div className="container">
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/news/administration/what-to-know-about-geos-pay-demands-an-in-depth-analysis-of-graduate-student-pay/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/ftes.png"
                    alt="Graduate student full time pay allotments"
                  />
                </div>
              </a>

              <p>
                What to know about GEO’s pay demands: An in-depth analysis of
                graduate student pay
              </p>
              <h5 className="text-normal">Visualizations with Datawrapper.</h5>
            </div>
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/web/the-michigan-daily-interactive-career-map/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/employment.png"
                    alt="The Michigan Daily interactive career map"
                  />
                </div>
              </a>

              <p>The Michigan Daily interactive career map</p>
              <h5 className="text-normal">
                The Michigan Daily surveyed its staffers to learn where they
                interned or worked this summer. From New York to Hong Kong, in
                journalism and out of it, here are the results.
              </h5>
            </div>
          </div>

          <div className="container">
            <div className="half-column iframeDisplayChart">
              <a href="https://specials.michigandaily.com/primary-22/">
                <div className="media-content">
                  <video autoPlay loop>
                    <source src={electionVideo} type="video/mp4" />
                  </video>
                </div>
              </a>

              <p>2022 Primary Election Voter Guide</p>
              <h5 className="text-normal">
                Built with React, ArchieML, and an AWS CloudFormation stack.
                Maps produced with D3, GDAL, Turfpy, and Folium.
              </h5>
            </div>
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/news/more-than-100-ann-arbor-restaurants-closed-in-the-past-three-years-how-have-these-closures-affected-the-community/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/covid.png"
                    alt="Ann Arbor COVID-19 restaurant closures"
                  />
                </div>
              </a>

              <p>
                More than 100 Ann Arbor restaurants closed in the past three
                years. How have these closures affected the community?
              </p>
              <h5 className="text-normal">
                Data scraped from Washtenaw County's restaurant inspection
                portal. Restaurant centroids repositioned with QGIS and mapped
                using building extent boundaries from Ann Arbor's open data
                portal. Part of a several month investigation into restaurant
                closures in Ann Arbor following the height of the COVID-19
                pandemic. Won SPJ Mark of Excellence in Food and Restaurant
                Journalism.
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/news/elections/2022-michigan-regular-and-special-election-results-democrat-flips-seat-in-gop-stronghold/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/districtflip.png"
                    alt="May 3rd Ann Arbor special congressional election"
                  />
                </div>
              </a>

              <p>
                2022 Michigan regular and special election results: Democrat
                flips seat in GOP stronghold
              </p>
              <h5 className="text-normal">
                {" "}
                Made with Folium and Leaflet for Michigan's May 3rd special
                congressional election. Data sourced from Michigan's open data
                portal. HTML edited to remove tileset and add legend.
              </h5>
            </div>
            <div className="half-column iframeDisplayChart">
              <a href="https://specials.michigandaily.com/orientation-22/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/orientation.jpg"
                    alt="Michigan Daily 2022 Orientation Edition"
                  />
                </div>
              </a>

              <p> The 2022 Orientation Edition</p>
              <h5 className="text-normal">
                Made with React. Efficient resource allocation with an
                interaction observer, PDF rendering.
              </h5>
            </div>
          </div>

          <div className="container">
            <div className="half-column iframeDisplayChart">
              <a href="#">
                <div className="media-content">
                  <iframe
                    title="Heat in Ann Arbor since 2001"
                    // className="iframeDisplayChart"
                    src="https://michigandaily.github.io/weather-graphic-small-multiples/"
                    frameBorder="0"
                  ></iframe>
                </div>
              </a>

              <p>
                How has Ann Arbor's climate changed in the last two decades?
              </p>

              <h5 className="text-normal">
                {" "}
                I used Google Earth Engine, GDAL (a projection CLI-tool),
                ai2html, high spatial resolution Landsat 8 satellite data, and
                high temporal resolution MODIS Terra satellite data to compile
                and visualize a two-decade history of heat in Ann Arbor. I also
                analyzed the University of Michigan's building-by-building heat
                resilience by bounding surface heat data to building land use.
              </h5>
            </div>
            <div className="half-column">
              <div className="media-content-full">
                <StaticImage
                  src="../../static/annarbormap.png"
                  alt="Heat map of Ann Arbor"
                />
              </div>
            </div>
          </div>

          <div className="container chart-row">
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/statement/the-statement-2021-sex-survey/">
                <div className="media-content">
                  <iframe
                    title="The Michigan Daily's 2021 Sex Edition"
                    className="iframeDisplayChart"
                    src="https://michigandaily.github.io/sex-edition-21-who/graphic/index.html"
                    frameBorder="0"
                  ></iframe>
                </div>
              </a>

              <p> The Statement 2021 Sex Survey</p>
              <h5 className="text-normal">
                {" "}
                Made with D3.js. Data sourced from the Daily's annual student
                survey for the Sex Edition.
              </h5>
            </div>
            <div className="half-column iframeDisplayChart">
              <a href="https://michigandaily.github.io/environment-emissions">
                <div className="media-content">
                  <iframe
                    title="University of Michigan C02 emissions"
                    className="iframeDisplayChart"
                    src="https://michigandaily.github.io/environment-emissions/graphic/index.html"
                    frameBorder="0"
                  ></iframe>
                </div>
              </a>

              <p>
                A look into the University's committment to carbon neutrality
              </p>
              <h5 className="text-normal">
                {" "}
                Made with D3.js. Data sourced from a University emissions report
                produced in 2020. Y-axis in tons of carbon dioxide. Accompanying
                story unpublished.
              </h5>
            </div>
          </div>

          <div className="container">
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/news/government/whitmers-new-budget-proposal-what-it-means-for-michigan-residents/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/appropriations.png"
                    alt="Michigan budget appropriations"
                  />
                </div>
              </a>

              <p>
                Whitmer’s new budget proposal: What it means for Michigan
                residents
              </p>

              <h5 className="text-normal"> Visualizations with Datawrapper.</h5>
            </div>
            <div className="half-column iframeDisplayChart">
              <a href="https://www.michigandaily.com/news/administration/a-look-back-at-eight-years-of-umich-under-former-u-m-president-mark-schlissel/">
                <div className="media-content">
                  <StaticImage
                    src="../../static/schlissel.png"
                    alt="Schlissel"
                  />
                </div>
              </a>
              <p>
                A look back at eight years of UMich under former U-M President
                Mark Schlissel
              </p>
              <h5 className="text-normal"> HTML and CSS</h5>
            </div>
          </div>
        </div>
  )
}

const Home = () => {
  const [activeTab, setActiveTab] = useState("Work")
  return (
    <div className="content">
      <div className="container-head">
        <div className="top-special">
          <Header />
          <h3 className="text-normal">mbilik at umich dot edu</h3>
          <p className="text-normal">
            Hi – I’m a fourth-year at the University of Michigan. I am
            completing a B.Sc. in computer science and sociology, and a minor in
            science, technology, and society studies. I apply empirical design
            studies and theoretical perspectives from sociology to inform
            human-AI tools that augment work. My work focuses on
            security, privacy, and trust. I am a former Data
            Editor, Managing Online Editor, and Data Journalist at The Michigan
            Daily.
          </p>
        </div>
      </div>
      {/* <div className="work-section">
        <div>
          <h5 className="text-normal">
            Much of my software oriented work involves
            full-stack engineering.
          </h5>
        </div>
      </div>
      */}

      <div className="container-head">
        <div className="top-special">
          <h2>
            <span
              className={activeTab === "Work" ? "active" : ""}
              onClick={() => setActiveTab("Work")}
            >
              Projects
            </span>{" "}
            <span
              className={activeTab === "Publications" ? "active" : ""}
              onClick={() => setActiveTab("Publications")}
            >
              {" "}
              Research
            </span>{" "}
            <span
              className={activeTab === "Design" ? "active" : ""}
              onClick={() => setActiveTab("Design")}
            >
              {" "}
              Design
            </span>{" "}
            {/* | */}
            {/* <span
              className={activeTab === "CV" ? "active" : ""}
              onClick={() => setActiveTab("CV")}
            >
              {" "}
              CV
            </span> */}
          </h2>
        </div>
      </div>

      {activeTab == "Work" && <Work/>}

      {activeTab == "Design" && (
        <div className="overarching-div">
          <div className="container">
            {/* <div className="half-column iframeDisplayChart">
              <div className="media-content-full">
                <StaticImage
                  src="../../static/geist-in-der.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div> */}
            <div className="half-column iframeDisplayChart">
              <div className="media-content-full">
                <StaticImage
                  className="smallImage"
                  src="../../static/geists.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div>

            <div className="half-column iframeDisplayChart">
              <div className="media-content-full">
                <StaticImage
                  src="../../static/t4sg.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div>
          </div>
          <div className="container">
          <div className="half-column iframeDisplayChart">
              <div className="media-content-full">
                <StaticImage
                  src="../../static/spudlab.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div>
            <div className="half-column iframeDisplayChart">
              <div className="media-content">
                <StaticImage
                  className="smallImage"
                  src="../../static/research.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="half-column iframeDisplayChart">
              <div className="media-content">
                <StaticImage
                  className="smallImage"
                  src="../../static/coco.png"
                  alt="Design for Tech 4 Social Good at the University of Michigan"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab == "Publications" && <Papers/>}

    </div>
  )
}
export default Home

export const Head = () => <SEO />
